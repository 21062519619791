<template>
  <section id="about" class="about">
    <div class="container">
      <h3>{{ title[lang] }}</h3>
      <p class="text" v-html="text[lang]"></p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    lang: String,
  },
  data() {
    return {
      title: {
        rus: "О компании",
        eng: "About",
        arabic: "",
      },
      text: {
        rus:
          "<b>Компания</b> является разработчиком и поставщиком уникальных системных решений в области управления структурой экономики и финансов организаций и физических лиц. <br>Мы поможем вам найти инновационные подходы к формированию системы управления интересующими вас объектами.    <br>Существенно повысим эффективность деятельности организационной структуры любого уровня с различной управленческой архитектурой и направлением деятельности.<br><br> Исполнительный директор: Хабеб Рахиман Плавумгал Абдул Рахиман.",
        eng:
          "<b> Company </b> is a developer and supplier of unique system solutions in the field of economic and financial structure management for organizations and individuals. <br> We will help you find innovative approaches to the formation of a management system for the objects you are interested in. <br> We will significantly increase the efficiency of the organizational structure of any level with different management architecture and line of business. <br><br> Executive Director: Habeeb Rahiman Plavumgal Abdul Rahiman",
        arabic: "",
      },
    };
  },
};
</script>

<style lang="scss">
b {
  font-weight: 500;
}

.about {
  position: relative;
  background: url("../../public/img/about.jpg") no-repeat center center / cover;

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-top: 20px;
    color: white;
  }

  .text {
    background: white;
    padding: 30px;
    text-align: left;
    max-width: 800px;
    margin-bottom: 20px;
  }
}

.about::after {
  background: #141924;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  opacity: 0.5;
  z-index: 1;
}
</style>

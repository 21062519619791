<template>
  <div id="app">
    <Header @changeLang='changeLang' />
    <Services :lang="lang" />
    <About :lang="lang" />
    <Partners :lang="lang" />
    <Contacts :lang="lang" />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Services from './components/Services.vue'
import Partners from './components/Partners.vue'
import About from './components/About.vue'
import Contacts from './components/Contacts.vue'


export default {
  components: { Header, Services, Partners, About, Contacts },
  name: 'app',
  data() {
    return {
      lang: 'rus'
    }
  },
  methods: {
    changeLang(lang){
      this.lang = lang
    }
  },
  
}
</script>

<style>
*{
  font-family: 'Fira Sans Condensed', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}

#app {
   color: rgb(4, 0, 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
}
</style>

<template>
<header>
    <el-drawer :visible.sync="drawer">
        <div class="menu">
            <li @click="drawer=false" v-scroll-to="item.link" class="item" v-for="item, i in menu" :key="i">
                {{item.name[lang]}}
            </li>

        </div>
        <div class="buttons">

            <el-button v-scroll-to="'#contacts'" style="color: white!important" type="primary">{{orderCall[lang]}}</el-button>
            <div class="lang-switcher">
                <div @click="changeLang('rus')" class="lang-item">RUS</div>
                <div @click="changeLang('eng')" class="lang-item">ENG</div>
                
            </div>
        </div>
    </el-drawer>
    <div class="top-line">
        <div class="container">
            <div class="logo">
                <div class="logo-img"></div>
                <div class="logo-text"> GOR Financial Investments and Consultancy F.Z.E.</div>
            </div>
            <div class="menu">
                <li v-scroll-to="item.link" class="item" v-for="item, i in menu" :key="i">
                    {{item.name[lang]}}
                </li>

            </div>
            <div class="buttons">

                <el-button v-scroll-to="'#contacts'" style="color: white!important" type="primary">{{orderCall[lang]}}</el-button>
                <div class="lang-switcher">
                    <div @click="changeLang('rus')" class="lang-item">RUS</div>
                    <div @click="changeLang('eng')" class="lang-item">ENG</div>
                    
                </div>
            </div>
            <div @click="drawer=true" class="burger-button">
                <span class="material-icons-outlined">
                    menu
                </span>
            </div>

        </div>
    </div>
    <div class="container" style="position:relative">
        <div class="gor-image"></div>
        <h2>{{title[lang]}}</h2>
        <div class="gor-text" ><div class="gor-div" v-html="gor[lang]"></div></div>
        
        <div class="pluses">
            <div class="plus" v-for="item,i in pluses" :key="i">
                <div class="image" :style="'background: url(\'./img/'+item.img+'\') no-repeat center center / cover'">

                </div>
                <div class="title">
                    {{item.title[lang]}}
                </div>

            </div>
        </div>
    </div>
</header>
</template>

<script>
export default {
    methods: {
        changeLang(lang) {
            this.lang = lang;
            this.$emit('changeLang', lang);
        }
    },
    data() {
        return {
            drawer: false,
            lang: 'rus',
            call: {
                rus: 'Позвонить',
                eng: 'Call',
                arabic: ''
            },
            orderCall: {
                rus: 'Заказать звонок',
                eng: 'Order call',
                arabic: ''
            },
            title: {
                rus: 'Компания GOR – Ваш корпоративный и личный финансовый консультант',
                eng: 'GOR is your corporate and personal financial advisor',
                arabic: ''
            },
            gor: {
                rus: '<b>ГОР</b> — хранитель тайной мудрости. Бог неба, солнца, света, царской власти, мужественности, победы и истины, почитаемый в Древнем Египте. ',
                eng: '<b> GOR </b> is the keeper of secret wisdom. God of the sky, sun, light, royal power, masculinity, victory and truth, revered in Ancient Egypt.',
                arabic: ''
            },
            more: {
                rus: 'Подробнее',
                eng: 'More',
                arabic: ''
            },
            menu: [{
                    name: {
                        rus: 'Услуги',
                        eng: 'Services',
                        arabic: ''
                    },
                    link: '#services'
                },
                {
                    name: {
                        rus: 'О компании',
                        eng: 'About',
                        arabic: ''
                    },
                    link: '#about'
                },
                {
                    name: {
                        rus: 'Партнеры',
                        eng: 'Partners',
                        arabic: ''
                    },
                    link: '#partners'
                },
                {
                    name: {
                        rus: 'Контакты',
                        eng: 'Contacts',
                        arabic: ''
                    },
                    link: '#contacts'
                }
            ],
            pluses: [{
                    img: '11.jpg',
                    title: {
                        rus: 'Создание, модернизация высокоэффективной организационной структуры компании',
                        eng: 'Creation, modernization of a highly efficient organizational structure of the company',
                        arabic: ''
                    },

                },
                {
                    img: '22.png',
                    title: {
                        rus: 'Формирование уникальной высокоэффективной модели бизнес процессов, модели управленческих и производственных процессов организации',
                        eng: 'Formation of a unique highly effective model of business processes, a model of management and production processes of the organization',
                        arabic: ''
                    },

                },
                {
                    img: '33.jpg',
                    title: {
                        rus: 'Постановка налогового, управленческого и финансового учета под реорганизованные процессы и технологии компании',
                        eng: 'Arrangement of tax, management and financial accounting for the reorganized processes and technologies of the company',
                        arabic: ''
                    },

                },
                {
                    img: '44.jpeg',
                    title: {
                        rus: 'Автоматизация управленческих производственных процессов, создание уникальной единой архитектуры  процессов управления предприятия',
                        eng: 'Automation of management production processes, creation of a unique unified architecture of enterprise management processes',
                        arabic: ''
                    },

                },
                {
                    img: '55.jpg',
                    title: {
                        rus: 'Создание  единой цифровой модели движения и учета управленческих, производственных процессов в разрезе рабочих мест, процессов, проектов, структурных подразделений',
                        eng: 'Creation of a unified digital model of movement and accounting of management, production processes in the context of workplaces, processes, projects, structural divisions',
                        arabic: ''
                    },

                },

            ]
        }
    },
}
</script>

<style lang="scss">
.gor-image {
    z-index: 2;
    background: url('../../public/gor.png') no-repeat center center / contain;
    top: 50px;
    bottom: 20px;
    left: calc(-50vw + 600px);
    width: calc(50vw - 600px);
    position: absolute;
    z-index: 2;
    
}
.gor-text {
    color: white;
    text-align: left;
    z-index: 2;
    max-width: 500px;
    margin-left: 5px;
    font-size: 20px;
    font-weight: 300;
}
.burger-button {
    display: none;
}

.lang-switcher {
    margin-left: 10px;

    .lang-item {
        cursor: pointer;
        transition: all .2s;
    }

    .lang-item:hover {
        color: #00A546;
    }
}

.call {
    text-decoration: none !important;
    color: rgb(4, 0, 255) !important;
    height: 100%;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
}

header::after {
    background: #141924;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    position: absolute;
    opacity: .3;
    z-index: 1;
}

header {
    position: relative;
    background: url('../../public/00.jpg') no-repeat center center / cover;

    padding: 32px;

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }

    .header-btn {
        cursor: pointer;
        padding: 15px 35px;
        color: white;
        border: 1px solid white;
        font-size: 14px;
        position: relative;
        z-index: 2;
        background: transparent;
        transition: all .2s;
        margin-top: 20px;
        margin-left: 5px;
    }

    .header-btn:hover {
        background: rgb(255, 255, 255);
        color: rgb(4, 0, 255);
    }

    .pluses {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-top: 20px;
        position: relative;
        z-index: 2;

        .plus:hover {
            box-shadow: 0px 0px 10px rgb(49, 49, 49);
        }

        .plus {

            flex: 1 1 150px;
            transition: all .2s;
            background: white;
            margin: 5px;

            .image {
                width: 100%;
                height: 200px;
            }

            .title {
                padding: 20px;
                font-size: 16px;
                color: rgb(4, 0, 255);
                text-align: left;
            }

            .description {
                font-size: 13px;
                padding: 10px 20px 30px;
                color: rgb(85, 85, 85);
                text-align: left;
            }
        }
    }

    h2 {
        z-index: 2;
        position: relative;
        font-size: 47px;
        font-weight: 200;
        letter-spacing: .5px;
        line-height: 48px;
        margin-bottom: 20px;
        margin-top: 100px;
        color: white;
        text-align: left;
        max-width: 600px;
        margin-left: 5px;
    }

    .top-line {

        z-index: 999;
        box-shadow: 0 2px 13px 0 rgb(0 0 0 / 25%);
        background: white;
        padding: 0 32px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 70px;

        .container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .logo {
            flex: 1 1 400px;
            display: flex;
            align-items: center;

            .logo-img {
                background: url('../../public/logo.png') no-repeat center center / contain;
                height: 60px;
                width: 50px;
                margin-right: 20px;
            }

            .logo-text {
                font-size: 20px;
                span {
                    font-weight: 500;
                    color: #3b3b3b;
                    
                }
            }
        }

        .menu {
            flex: 1 1 350px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .item {
                margin: 0 15px;
                list-style: none;
                font-size: 18px;
                transition: all .3s;
                cursor: pointer;
                font-weight: 400;
            }

            .item:hover {
                color: #00A546;
            }
        }

        .buttons {
            .el-button {
                border-radius: 0 !important;
                padding: 10px !important;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: .3px;
                height: fit-content;

            }

            flex: 1 1 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.el-drawer{
    width: 300px!important;
}
@media (max-width: 1200px) {
    .burger-button {
        display: block;
    }
    .el-drawer__header{
        background: white;
    }
    .el-drawer__header::after{
        display: none;
    }
    .el-drawer__body{
        margin-top: 0!important;
        .menu{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .item{
                list-style: none;
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
        .buttons{
            margin-top: 20px;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }
        .lang-switcher{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        
    }
    .top-line {
        .container {

            .menu,
            .buttons {
                display: none;

            }
        }
    }
}
@media (max-width: 1000px){
    header{
        .pluses{
            .plus{
                display: flex;
                
            }
            .image{
                height: 100%!important;
                width: 150px!important;
            }
            .title{
                flex: 1;
            }
        }
    }
}
@media (max-width: 600px){
    header {
        h2{
            font-size: 30px;
        }
    }
    header .top-line .logo{
    flex: 1 1 150px
}
}
@media (max-width: 450px){
    header {
        h2{
            font-size: 22px;
            line-height: 23px;
        }
    }
    .image{
        display: none;
    }
    .pluses{
        flex-direction: column;
    }
    .plus{
        flex: unset!important
    }
}

</style>

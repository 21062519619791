<template>
<section id="contacts" class="contacts-sect">
    <div class="container">
        <h3>{{title[lang]}}</h3>
        <div class="contacts">
            <div class="left">
                <el-form label-width="140px">
                    <el-form-item :label="p[lang]+':'">
                        <a href="tel:+971524318066">+971524318066</a>
                        <a href="tel:+73452689888">+73452689888</a>
                        <a href="tel:+79829884499">+79829884499</a>
                    </el-form-item>
                    <el-form-item :label="m[lang]+':'">
                        <a >COMPANY.GOR.21@gmail.com</a>
                        
                    </el-form-item>
                </el-form>
            </div>
            <div class="right">
                <el-form label-width="120px">
                    <el-form-item :label="n[lang]">
                        <el-input v-model="name"></el-input>
                    </el-form-item>
                    <el-form-item :label="p[lang]">
                        <el-input v-model="phone"></el-input>
                    </el-form-item>
                    <el-form-item :label="q[lang]">
                        <el-input type="textarea" :rows="3" v-model="quest"></el-input>
                    </el-form-item>
                    <el-form-item><el-button @click="send" :disabled="disabled"  :loading="loading" type="primary">{{s[lang]}}</el-button></el-form-item>
                    
                </el-form>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
export default {
    props:{
        lang: String
    },
    methods:{
        send () {
            this.loading = true;
            let data = new FormData;
            data.append('name', this.form.name)
            data.append('phone', this.form.phone)
            data.append('message', this.form.message)
            axios.post('/mailer/mailer.php', data).then(response => {
                console.log(response.data);
                this.loading = false;
                this.disabled = true;
        })
        }
    },
    data() {
        return {
            title: {
                rus: 'Контакты',
                eng: 'Contacts',
                arabic: ''
            },
            m: {
                rus: 'Адрес эл. почты',
                eng: 'Mail',
                arabic: ''
            },
            n: {
                rus: 'Имя',
                eng: 'Name',
                arabic: ''
            },
            p: {
                rus: 'Телефон',
                eng: 'Phone',
                arabic: ''
            },
            q: {
                rus: 'Сообщение',
                eng: 'Message',
                arabic: ''
            },
            s: {
                rus: 'Отправить',
                eng: 'Send',
                arabic: ''
            },
            name: '',
            phone: '',
            quest: '',
            loading: false,
            disabled: false
        }
    },
}
</script>

<style lang="scss">
.contacts-sect {
    background: rgba(243, 243, 243, 0.425);
    margin-bottom: 0!important;
    .container {
        max-width: 800px;
    }
}

.contacts {
    display: flex;
    align-items: stretch;
    .el-button{
        border-radius: 0!important;
    }
    * {
        font-size: 18px !important;
        font-weight: 300;
    }

    .left,
    .right {
        flex: 1 1 300px;
    }

    .left {
        .el-form-item__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        a{
            text-decoration: none;
            color: #00A546;
        }
    }
    .el-input, .el-input__inner, .el-textarea__inner, .el-textarea{
        border-radius: 0!important;
    }
}
@media (max-width: 800px){
    .contacts{
        flex-direction: column;
    }
    .contacts .left, .contacts .right{
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 350px){
    .el-form-item{
        display: flex!important;
        flex-direction: column!important;
    }
    .el-form-item__label{
        width: 100%!important;
        text-align: center!important;
        
    }
    .el-form-item__content{
        margin-left: 0!important;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center!important;
    }
}
</style>

<template>
<section id="partners">
    <div class="container">
        <h3>{{title[lang]}}</h3>
        <div class="partners">
            <el-popover v-for="item,i in partners" :key="i" placement="top-start" trigger="hover" :content="item.name[lang]">
                <el-button class="partner" :style="'background: url(\'./img/'+item.img+'\') no-repeat center center / contain'" slot="reference"></el-button>
            </el-popover>

        </div>
    </div>
</section>
</template>

<script>
export default {
    props: {
        lang: String
    },
    data() {
        return {
            title: {
                rus: 'Партнеры',
                eng: 'Partners',
                arabic: ''
            },
            partners: [{
                    img: '2.png',
                    name: {
                        rus: 'ООО «ННК-геофизика»',
                        eng: 'LLC "NNK-Geophysics"',
                        arabic: 'LLC "NNK-Geophysics"'
                    }
                },
                {
                    img: '1.jpg',
                    name: {
                        rus: 'ПАО «Геотексейсморазведка»',
                        eng: 'PJSC "Geotexeismorazvedka"',
                        arabic: 'PJSC "Geotexeismorazvedka"'
                    }
                },
                {
                    img: '3.jpg',
                    name: {
                        rus: 'ПАО «НК  «РОСНЕФТЬ»',
                        eng: 'PJSC "NK" ROSNEFT "',
                        arabic: 'PJSC "NK" ROSNEFT "'
                    }
                },
                {
                    img: '4.jpg',
                    name: {
                        rus: 'ООО «Правдинская геологоразведочная экспедиция»',
                        eng: 'LLC "Pravdinskaya Geological Prospecting Expedition"',
                        arabic: 'LLC "Pravdinskaya Geological Prospecting Expedition"'
                    }
                },
                {
                    img: '5.png',
                    name: {
                        rus: 'ПАО «Лукойл»',
                        eng: 'PJSC "LUKOIL"',
                        arabic: 'PJSC "LUKOIL"'
                    }
                },
                {
                    img: 'kpmg.png',
                    name: {
                        rus: 'KPMG',
                        eng: 'KPMG',
                        arabic: 'KPMG'
                    }
                },
                {
                    img: 'pwc.jpg',
                    name: {
                        rus: 'PwC',
                        eng: 'PwC',
                        arabic: 'PwC'
                    }
                },
            ]
        }
    },
}
</script>

<style lang="scss">
.partners {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;

    .partner {
        height: 100px;
        width: 200px;
        border: none !important;
        
        transition: all .2s;
    }

    .partner:hover {
        filter: none;
    }
}

.el-popover {
    word-break: keep-all !important;
}
</style>

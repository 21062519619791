<template>
<section id="services">
    <div class="container">
        <h3>{{title[lang]}}</h3>
        <div class="services">
            <div class="service" v-for="item, i in services" :key="i">
                <span v-if="item.icon != 'gavel'" class="material-icons-outlined">
                    {{item.icon}}
                </span>

                <span v-if="item.icon == 'gavel'" class="material-icons-outlined">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                        <path d="M12 3c-1.27 0-2.4.8-2.82 2H3v2h1.95L2 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3L6.05 7h3.12c.33.85.98 1.5 1.83 1.83V20H2v2h20v-2h-9V8.82c.85-.32 1.5-.97 1.82-1.82h3.13L15 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3l-2.95-7H21V5h-6.17C14.4 3.8 13.27 3 12 3m0 2a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m-6.5 5.25L7 14H4l1.5-3.75m13 0L20 14h-3l1.5-3.75z" fill="#25b260" /></svg>
                </span>
                <div class="name">
                    {{item.name[lang]}}
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    props: {
        lang: String
    },
    data() {
        return {
            title: {
                rus: 'Услуги',
                eng: 'Services',
                arabic: ''
            },
            services: [{
                    name: {
                        rus: 'Оценка рисков и подготовка рекомендаций при реализации инвестиционных  проектов на территории ОАЭ, РОССИИ, СНГ',
                        eng: 'Risk assessment and preparation of recommendations for the implementation of investment projects in the UAE, RUSSIA, CIS',
                        arabic: ''
                    },
                    icon: 'description'
                },
                {
                    name: {
                        rus: 'Разработка и подготовка экспресс-анализа проектов с использованием необщедоступной информации с учетом глубоких знаний состояния ведущих предприятий и межотраслевого состояния экономики многих стран.',
                        eng: 'Development and preparation of express analysis of projects using non-public information, taking into account in-depth knowledge of the state of leading enterprises and the intersectoral state of the economy of many countries.',
                        arabic: ''
                    },
                    icon: 'euro'
                },
                {
                    name: {
                        rus: 'Мониторинг экономической ситуации и аналитические прогнозы на территории ОАЭ, России ',
                        eng: 'Monitoring the economic situation and analytical forecasts in the UAE, Russia',
                        arabic: ''
                    },
                    icon: 'legend_toggle'
                },
                {
                    name: {
                        rus: 'Консультирование в вопросах размещения акций, облигаций, структурных продуктов и других финансовых инструментов',
                        eng: 'Advising on the placement of shares, bonds, structured products and other financial instruments',
                        arabic: ''
                    },
                    icon: 'request_quote'
                },

                {
                    name: {
                        rus: 'Подготовка и оценка бизнес-планов  предприятий',
                        eng: 'Preparation and evaluation of business plans of enterprises',
                        arabic: ''
                    },
                    icon: 'trending_up'
                },

                {
                    name: {
                        rus: 'Консультации в области международного права',
                        eng: 'Consulting in the field of international law',
                        arabic: ''
                    },
                    icon: 'gavel'
                },
                {
                    name: {
                        rus: 'Постановка бухгалтерского и налогового учета',
                        eng: 'Organization of accounting and tax accounting',
                        arabic: ''
                    },
                    icon: 'business_center'
                },
                {
                    name: {
                        rus: 'Консультации по вопросам из области трудового законодательства',
                        eng: 'Consulting on issues related to labor law',
                        arabic: ''
                    },
                    icon: 'work'
                },
            ]
        }
    },
}
</script>

<style lang="scss">
.el-form-item__label{
    color: rgb(4, 0, 255)!important
}
.el-popper{
    color: rgb(4, 0, 255)!important
}
section {
    padding: 32px;
    margin: 40px 0;
}

h3 {
    font-size: 35px;
    margin-bottom: 20px;
    color: rgb(4, 0, 255);
    text-transform: uppercase;
    font-weight: normal;
}

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    max-width: 1200px;
    margin: 0 auto;

    .service {
        flex: 1 1 230px;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        padding: 20px;
        border: 1px solid #cacaca;
        transition: all .2s;
    }

    .service:hover {
        border: 1px solid #00A546;
        box-shadow: 0px 0px 10px rgb(145, 145, 145);
    }

    .material-icons-outlined {
        color: #00a545da;
        font-size: 50px;
        border-radius: 50%;
        border: 1px solid #00A546;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        margin: 20px 0;
    }

    .name {
        text-align: center;
    }
}

@media (max-width: 350px) {
    .service {
        flex: 1 1 150px !important;
    }
}
</style>
